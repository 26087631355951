import Vue from "vue";
import Router from "vue-router";
import firebase from "firebase";

import Login from "@/views/Login";
import Register from "@/views/Register";
import Dashboard from "@/views/Dashboard";
import BrandImport from "@/views/BrandImport.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "*",
      component: () => import("@/views/NotFound.vue")
    },
    {
      path: "/",
      redirect: "/dashboard"
    },
    {
      path: "/brandmanager/:id",
      name: "Brand Manager",
      component: () => import("@/views/BrandManager/BrandManager.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/data-privacy",
      name: "DataPrivacy",
      component: () => import("@/views/DataPrivacy.vue")
    },
    {
      path: "/editor/:file/:id/:isParent?/:templateId?",
      name: "Editor",
      component: () => import("@/views/Editor/Editor.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/editor-new/:file/:isParent?/:templateId?",
      name: "EditorNew",
      component: () => import("@/views/Editor/Editor.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/slide-preview/:file/:id/:teamType/:isParent?/:templateId?",
      name: "SlidePreview",
      component: () => import("@/views/Editor/Editor.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/invite/:id",
      name: "Invite",
      component: () => import("@/views/Invite.vue")
    },
    {
      path: "/imprint",
      name: "Imprint",
      component: () => import("@/views/Imprint.vue")
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/password-reset",
      name: "PasswordReset",
      component: () => import("@/views/PasswordReset.vue")
    },
    {
      path: "/preview-slide/:teamId/:slideId/:orientation/:templateId?",
      name: "PreviewSlide",
      component: () => import("@/views/PreviewSlide.vue")
    },
    {
      path: "/preview-template/:teamId/:templateId",
      name: "PreviewTemplate",
      component: () => import("@/views/PreviewTemplate.vue")
    },
    {
      path: "/r_vh1toh8ee2q6kvsy",
      // path: "/register",
      name: "Register",
      component: Register
    },
    {
      path: "/ascr_vh1toh8ee2q6kvsy",
      name: "AddScreen",
      component: () => import("@/views/AddScreen.vue")
    },
    {
      path: "/at_vh1toh8ee2q6kvsy",
      name: "AddTeam",
      component: () => import("@/views/AddTeam.vue")
    },
    {
      path: "/screens",
      name: "Screens",
      component: () => import("@/views/Screens.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/settings/:id",
      name: "Settings",
      component: () => import("@/views/Settings/Settings.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/slides/:id",
      name: "Slides",
      component: () => import("@/views/Slides/Slides.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/support",
      name: "Support",
      component: () => import("@/views/Support.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/templates/:id",
      name: "Templates",
      component: () => import("@/views/Templates/Templates.vue"),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/terms-of-use",
      name: "TermsOfUse",
      component: () => import("@/views/TermsOfUse.vue")
    },
    {
      path: "/brand-import",
      name: "BrandImport",
      component: BrandImport,
      meta: {
        requiresAuth: true
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(rec => rec.meta.requiresAuth);
  const currentUser = firebase.auth().currentUser;

  if (requiresAuth && !currentUser) {
    next("/login");
  } else if (requiresAuth && currentUser) {
    next();
  } else if (to.name === "Login" && currentUser) {
    next("/");
  } else {
    next();
  }
});

export default router;
