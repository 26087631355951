<template>
  <div class="team-dropdown">
    <div
      class="team-dropdown__chosen"
      @click="showDropdownList = !showDropdownList"
    >
      <div class="team-dropdown__chosen-content">
        <div
          class="team-dropdown__chosen-logo"
          v-if="currentTeam.avatar"
          :style="{
            'background-image': 'url(' + currentTeam.avatar + ')'
          }"
        ></div>
        <ReplacementLogo
          :name="currentTeam.name"
          v-else
          class="team-dropdown__chosen-logo--unset"
        />
        <div class="team-dropdown__chosen-name">
          {{ currentTeam.name }}
        </div>
      </div>
      <inline-svg
        class="team-dropdown__down"
        :src="require(`../assets/icons/Down.svg`)"
        aria-label="Down"
      ></inline-svg>
    </div>
    <div class="team-dropdown__list" v-if="showDropdownList">
      <div class="team-dropdown__search">
        <inline-svg
          class="team-dropdown__search-icon"
          :src="require('../assets/icons/Search.svg')"
          aria-label="icon"
        ></inline-svg>
        <input
          class="team-dropdown__search-field"
          type="text"
          v-model="searchTerm"
          :placeholder="$t('pages.editor.branches.placeholder')"
        />
      </div>
      <div
        class="team-dropdown__item"
        v-for="(team, teamIndex) in searchList"
        :key="teamIndex"
        @click="chooseTeam(team)"
      >
        <div class="team-dropdown__item-content">
          <div
            class="team-dropdown__item-logo"
            :style="{
              'background-image': 'url(' + team.avatar + ')'
            }"
            v-if="team.avatar"
          ></div>
          <ReplacementLogo
            :name="team.name"
            v-else
            class="team-dropdown__item-logo--unset"
          />
          <div class="team-dropdown__item-name">
            {{ team.name }}
          </div>
        </div>
        <div
          class="team-dropdown__divider"
          v-if="teamIndex < teams.length - 1"
        ></div>
      </div>
      <div
        class="team-dropdown__logout team-dropdown__item-content"
        @click="onLogout"
      >
        <inline-svg
          class="team-dropdown__logout-icon"
          :src="require(`../assets/icons/Logout.svg`)"
          aria-label="Down"
        ></inline-svg>
        <div class="team-dropdown__item-name">
          {{ $t("components.teamDropdown.logout") }}
        </div>
      </div>
    </div>
    <div
      class="team-dropdown__backdrop"
      @click="showDropdownList = false"
      v-if="showDropdownList"
    ></div>
  </div>
</template>

<script>
import ReplacementLogo from "@/components/ReplacementLogo";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ReplacementLogo
  },
  data: () => ({
    searchTerm: "",
    showDropdownList: false
  }),
  computed: {
    ...mapGetters("teams", {
      teams: "getTeams",
      currentTeam: "getCurrentTeam"
    }),
    searchList() {
      if (this.searchTerm) {
        return this.teams.filter(team => {
          return team.name
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase());
        });
      }

      return this.teams;
    }
  },
  methods: {
    ...mapActions("teams", ["setCurrentTeam"]),
    ...mapActions("user", ["logout"]),
    chooseTeam(team) {
      this.setCurrentTeam(team.id);
      this.showDropdownList = false;
      window.location.reload();
    },
    onLogout() {
      this.logout();
    }
  }
};
</script>

<style lang="scss">
.team-dropdown {
  cursor: pointer;
  position: relative;
  z-index: 5;

  &__search {
    @apply w-full relative mb-4;

    &-icon {
      position: absolute;
      top: 11px;
      left: 10px;
    }

    &-field {
      @apply bg-ls-gray-100 rounded-lg pl-8 pr-3 py-2 w-full;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__chosen {
    @apply flex justify-between items-center p-1 rounded-lg font-semibold text-black text-xs;
    z-index: 5;

    &-content {
      @apply flex items-center;
    }

    &-logo {
      @apply bg-cover w-6 h-6 mr-2 rounded-lg;

      &--unset {
        @apply w-6 h-6 mr-2 rounded-lg;
      }
    }

    &-name {
      width: calc(100% - 32px);
    }
  }

  &__down {
    path {
      fill: #7b7b7c;
    }
  }

  &__list {
    @apply px-4 pt-4 pb-2 absolute bg-white w-full shadow-ls-shadow rounded;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.16),
      0px 2px 12px rgba(0, 0, 0, 0.18);
    min-height: 340px;
    max-height: 500px;
    z-index: 5;
    overflow-y: scroll;
  }

  &__divider {
    height: 1px;
    @apply bg-ls-gray-200 w-full my-2;
  }

  &__item {
    @apply font-semibold text-black text-xs;

    &:hover {
      @apply bg-ls-gray-100;
    }

    &-content {
      @apply flex items-center;
    }

    &-logo {
      @apply bg-cover w-6 h-6 mr-2 rounded-lg;

      &--unset {
        @apply w-6 h-6 mr-2 rounded-lg;
      }
    }

    &-name {
      width: calc(100% - 32px);
    }
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100vw;
    height: 100vh;
    cursor: default;
  }

  &__logout {
    @apply border-t border-ls-gray-200 flex text-ls-gray-800 text-xs font-semibold px-1 py-2 mt-2 w-full;

    &-icon {
      margin-right: 12px;

      path {
        fill: #7b7b7c;
      }
    }
  }
}
</style>
