<template>
  <div class="dashboard">
    <div class="dashboard__content">
      <Alert
        :title="$t('pages.dashboard.screenAlertHeading')"
        class="dashboard__screen-alert"
        v-if="longerThan24HoursOfflineScreens?.length > 0"
      >
        <template #content>
          <ul class="dashboard__screens--offline">
            <li
              v-for="(screen, index) in longerThan24HoursOfflineScreens"
              :key="index"
            >
              {{ screen.name }}
            </li>
          </ul>
        </template>
      </Alert>
      <h2 class="dashboard__heading">{{ $t("pages.dashboard.title") }}</h2>
      <div class="dashboard__divider"></div>
      <div class="dashboard__creation-btns">
        <Button
          class="dashboard__creation-btn--slide"
          theme="secondary"
          @click="onCreateSlide"
        >
          {{ $t("pages.dashboard.createSlideBtn") }}
        </Button>
        <div class="only-desktop">
          <Button
            theme="secondary"
            @click="onCreateTemplate"
            v-if="showCreateTemplateBtn"
          >
            {{ $t("pages.dashboard.createTemplateBtn") }}
          </Button>
        </div>
      </div>

      <div class="dashboard__templates">
        <div class="dashboard__templates-heading">
          <div class="dashboard__templates-title">
            {{ $t("pages.dashboard.templates") }}
          </div>
          <div class="dashboard__templates-view-all-btn" @click="goToTemplates">
            {{ $t("pages.dashboard.viewAllTemplates") }}
          </div>
        </div>
        <div class="dashboard__templates-list" v-if="templates">
          <div
            class="dashboard__template"
            v-for="template in sortedTemplates"
            :key="template.id"
          >
            <Template
              :template="template.data"
              :is-parent="false"
              :id="template.id"
              :team-id="team.id"
            />
          </div>
        </div>
      </div>

      <div class="dashboard__slides">
        <div class="dashboard__slides-heading">
          {{ $t("pages.dashboard.slides") }}
        </div>
        <div class="dashboard__slides-list">
          <div
            class="dashboard__slide"
            v-for="(slide, index) in filteredSlides"
            :key="index"
            @click="openEditor(slide)"
          >
            <Slide :slide="slide"></Slide>
          </div>
        </div>
      </div>
    </div>

    <Modal
      class="dashboard__modal"
      v-if="showCreateModal"
      @close="showCreateModal = false"
      :heading="$t('components.create.heading')"
    >
      <div slot="modal-content">
        <Create :show-heading="false" class="dashboard__modal-content"></Create>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Alert from "@/components/Alert";
import Button from "@/components/Button";
import Create from "@/components/Create";
import Modal from "@/components/Modal";
import Template from "@/components/Template";
import Slide from "@/components/Slide";
import dayjs from "dayjs";

export default {
  components: {
    Alert,
    Button,
    Create,
    Modal,
    Template,
    Slide
  },
  data: () => ({
    showCreateModal: false
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      parentTeam: "getParentTeam",
      childSettings: "getChildSettings",
      role: "getRole"
    }),
    ...mapGetters("templates", {
      templates: "getTemplates"
    }),
    ...mapGetters("user", {
      user: "getUserProfile"
    }),
    ...mapGetters("screens", {
      user: "getTimestamps"
    }),
    slides() {
      if (this.team) {
        return this.team.slides;
      }

      return [];
    },
    filteredSlides() {
      let slides = this.slides?.filter(item => item !== undefined);
      slides = slides?.filter(slide => slide.status === "published");

      return slides
        ?.filter(slide => {
          if (!slide?.id) {
            return false;
          }
          return this.slideIsNotBroken(slide);
        })
        .sort((a, b) => {
          return b.lastChangedDate.localeCompare(a.lastChangedDate);
        })
        .slice(0, 6);
    },
    sortedTemplates() {
      let t = [...this.templates];

      return t
        .sort((a, b) => {
          return b.data.lastChangedDate.localeCompare(a.data.lastChangedDate);
        })
        .filter(
          template =>
            template.data?.status !== "archived" &&
            template.data?.status !== "deleted"
        )
        .slice(0, 3);
    },
    screens() {
      if (this.timestamps) {
        return this.team.screens.map(screen => {
          screen.timestamp = this.timestamps[screen.id].timestamp;
          return screen;
        });
      }
      return this.team.screens ? this.team.screens : [];
    },
    showCreateTemplateBtn() {
      if (this.role === "editor") {
        return false;
      }
      if (this.parentTeam) {
        return this.childSettings?.createTemplates;
      }

      return true;
    },
    longerThan24HoursOfflineScreens() {
      let offlineScreens = this.screens?.filter(screen => {
        if (screen.timestamp) {
          let date = dayjs.unix(screen.timestamp / 1000); // since the value is saved in miliseconds we divide by 1000
          if (date.isBefore(dayjs().subtract(24, "hour"))) {
            return screen;
          }
        }
      });

      return offlineScreens;
    }
  },
  mounted() {
    const storedTeam = sessionStorage.getItem("currentTeam");

    if (!this.user?.currentTeam && !storedTeam) {
      this.fetchUserProfile().then(() => {
        this.fetchCurrentTeam(this.user.currentTeam).then(() => {
          this.fetchTemplates(this.team.id);
        });
      });
    } else if (storedTeam) {
      if (!this.templates) {
        this.fetchTemplates(storedTeam);
      }
    } else {
      this.fetchCurrentTeam(this.user.currentTeam).then(() => {
        this.fetchTemplates(this.team.id);
      });
    }
  },
  methods: {
    ...mapActions("templates", ["fetchTemplates"]),
    ...mapActions("teams", ["fetchCurrentTeam"]),
    ...mapActions("user", ["fetchUserProfile"]),
    ...mapActions("slides", ["setOriginPage"]),
    slideIsNotBroken(slide) {
      if (slide.type === "image") {
        return !!slide.url.image?.landscape || !!slide.url.image?.portrait;
      } else if (slide.type === "video") {
        return !!slide.url.video?.landscape || !!slide.url.video?.portrait;
      } else {
        return true;
      }
    },
    onCreateSlide() {
      this.showCreateModal = true;
    },
    onCreateTemplate() {
      window.location.href = `/designer/?id=${this.team.id}`;
    },
    checkParentTemplate(slide) {
      if (slide.template.isParentTemplate) {
        return "1";
      } else if (slide.template.isRootTemplate) {
        return "2";
      } else if (slide.template.isLobbySpaceTemplate) {
        return "3";
      } else {
        return "0";
      }
    },
    openEditor(slide) {
      this.setOriginPage(this.$route.path);

      if (slide.type === "template") {
        this.$router.push({
          name: "Editor",
          params: {
            file: slide.type,
            id: slide.id,
            isParent: this.checkParentTemplate(slide),
            templateId: slide.template.id
          }
        });
      } else {
        this.$router.push({
          name: "Editor",
          params: {
            file: slide.type,
            id: slide.id
          }
        });
      }
    },
    goToTemplates() {
      this.$router.push({
        name: "Templates",
        params: {
          id: "organization"
        }
      });
    }
  }
};
</script>

<style lang="scss">
.dashboard {
  min-height: 100vh;
  width: 100%;
  padding: 1.5rem;
  margin: 0 auto;

  &__heading {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
  }

  &__divider {
    @apply bg-gray-300 h-px w-full mt-4 mb-7;
  }

  &__creation-btns {
    @apply flex;
  }

  &__creation-btn--slide {
    @apply mr-4;
  }

  &__templates {
    @apply flex flex-col mt-7 p-6 border border-gray-300;

    &-heading {
      @apply flex mb-2 justify-between;
    }

    &-title {
      font-size: 18px;
      font-weight: 600;
    }

    &-view-all-btn {
      @apply font-semibold text-sm text-ls-purple cursor-pointer;
    }

    &-list {
      @apply flex flex-wrap;
    }
  }

  &__slides {
    @apply mt-6 px-6;

    &-heading {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    &-list {
      @apply flex flex-wrap;
    }
  }

  &__slide,
  &__template {
    @apply w-full;
  }

  &__modal-content {
    border: none;
    max-width: 810px;
    overflow-y: auto;
  }

  &__screen-alert {
    @apply mb-4;
  }

  &__screens--offline {
    list-style: disc !important;
    margin-left: 16px !important;
  }
}

@media screen and (min-width: 900px) {
  .dashboard {
    width: 810px;
    padding: 36px 0;

    &__content {
      padding: 24px 0;
    }

    &__heading {
      font-size: 48px;
    }

    &__templates-list {
      @apply flex-nowrap;
    }

    &__template {
      @apply mr-4;
      flex: 0 1 calc(33.333%);

      &:nth-child(3n) {
        margin: 0;
      }
    }

    &__slide {
      flex: 0 1 calc(33.333% - 11px);
      margin-right: 16px;

      &:nth-child(3n) {
        margin: 0;
      }
    }
  }
}
</style>
