import router from "../router/index";
import firebase from "firebase";
import "firebase/storage";

import { db } from "../firebase/init";

export default {
  namespaced: true,
  state: {
    language: localStorage.getItem("language"),
    currentUser: null,
    userProfile: null,
    logData: null
  },
  getters: {
    getUserProfile(state) {
      return state.userProfile;
    },
    getLanguage(state) {
      return state.language;
    },
    getCurrentUser(state) {
      return state.currentUser;
    },
    getLogData(state) {
      return state.logData;
    }
  },
  mutations: {
    SET_CURRENT_USER(state, val) {
      state.currentUser = val;
    },
    SET_USER_PROFILE(state, val) {
      state.userProfile = val;
    },
    SET_LANGUAGE(state, val) {
      state.language = val;
    },
    SET_LOG_DATA(state, val) {
      state.logData = val;
    }
  },
  actions: {
    async uploadAccountAvatar({ state, commit }, image) {
      let date = new Date();
      let seconds = date.getTime() / 1000;
      let blob = await fetch(image.src).then(r => r.blob());

      const uploadTask = firebase
        .storage()
        .ref()
        .child(`users/${state.userProfile.user_id}/avatar/${seconds}`)
        .put(blob);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          commit("progress/SET_PROGRESS_PICTURE", progress, { root: true });

          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        error => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log("File available at", downloadURL);

            let user = state.userProfile;
            user.avatar = downloadURL;

            firebase
              .database()
              .ref("users/" + user.user_id)
              .update(user);
          });
        }
      );
    },
    async updateAccountAvatar({ state, commit }, image) {
      let date = new Date();
      let seconds = date.getTime() / 1000;
      const oldUrl = state.userProfile.avatar;
      let blob = await fetch(image.src).then(r => r.blob());

      const uploadTask = firebase
        .storage()
        .ref()
        .child(`users/${state.userProfile.user_id}/avatar/${seconds}`)
        .put(blob);

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          commit("progress/SET_PROGRESS_PICTURE", progress, { root: true });

          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        error => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log("File available at", downloadURL);

            let user = state.userProfile;
            user.avatar = downloadURL;

            firebase
              .database()
              .ref("users/" + user.user_id)
              .update(user);

            firebase
              .storage()
              .refFromURL(oldUrl)
              .delete();
          });
        }
      );
    },
    fetchUserProfile({ commit, dispatch, state }) {
      return new Promise(resolve => {
        const userRef = db.ref(`users/${state.currentUser?.uid}`);

        userRef.on("value", snapshot => {
          const data = snapshot.val();
          resolve(data);
          commit("SET_USER_PROFILE", data);
          commit("SET_LANGUAGE", data.language);
          if (data.teams) {
            commit("teams/SET_TEAMS", data.teams, { root: true });
          }

          const storedTeam = sessionStorage.getItem("currentTeam");
          if (storedTeam) {
            dispatch("teams/fetchCurrentTeam", storedTeam, {
              root: true
            });

            dispatch("screens/fetchScreensTimestamps", storedTeam, {
              root: true
            });
          } else if (data.currentTeam) {
            sessionStorage.setItem("currentTeam", data.currentTeam);
            dispatch("teams/fetchCurrentTeam", data.currentTeam, {
              root: true
            });

            dispatch("screens/fetchScreensTimestamps", data.currentTeam, {
              root: true
            });
          }
        });
      });
    },
    updateProfile({ state, dispatch }, data) {
      return new Promise((resolve, reject) => {
        db.ref("users/" + state.currentUser.uid)
          .update(data)
          .then(() => {
            dispatch("updateUserTeams");
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    updateUserTeams({ state }) {
      let teams = state.userProfile.teams;
      let promises = [];

      teams.map(team => {
        let teamRef = db
          .ref(`teams/${team.id}/users/${state.userProfile.user_id}`)
          .update({
            name: state.userProfile.name,
            email: state.userProfile.email
          });
        promises.push(teamRef);
      });

      Promise.all(promises);
    },
    updateEmail({ state }, { newMail, oldPassword }) {
      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        state.currentUser.email,
        oldPassword
      );

      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          user
            .updateEmail(newMail)
            .then(function() {
              db.ref("users/" + state.currentUser.uid).update({
                email: newMail
              });
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          console.log(error);
        });
    },
    updatePassword({ state }, { oldPassword, newPassword }) {
      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        state.currentUser.email, // references the user's email address
        oldPassword
      );

      return new Promise((resolve, reject) => {
        user
          .reauthenticateWithCredential(credential)
          .then(() => {
            user
              .updatePassword(newPassword)
              .then(function() {
                resolve();
              })
              .catch(error => {
                console.log(error);
                reject();
              });
          })
          .catch(error => {
            reject();
            console.log(error);
          });
      });
    },
    clearData({ commit }) {
      commit("SET_CURRENT_USER", null);
      commit("SET_USER_PROFILE", null);
    },
    setCurrentUser({ commit }, user) {
      commit("SET_CURRENT_USER", user);
    },
    getAuthState({ commit, dispatch }) {
      firebase.auth().onAuthStateChanged(user => {
        commit("SET_CURRENT_USER", user);

        if (user) {
          dispatch("fetchUserProfile");
        }
      });
    },
    fetchLogData({ commit }) {
      return new Promise(resolve => {
        const logRef = db.ref("generalAssets/update/");

        logRef.on("value", snapshot => {
          const data = snapshot.val();
          resolve(data);
          commit("SET_LOG_DATA", data);
        });
      });
    },
    logout({ dispatch }) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          dispatch("clearData");
          sessionStorage.removeItem("currentTeam");
          dispatch("teams/teamLogout", null, { root: true });
          window.location.reload();
          router.push({
            name: "Login"
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
