<template>
  <div class="app">
    <NavBar
      class="navbar--desktop"
      v-if="showNavBar && hasTeams && hasUser"
    ></NavBar>
    <NavBarMobile
      class="navbar--mobile"
      v-if="showNavBar && hasTeams && hasUser"
    ></NavBarMobile>
    <div v-if="user && profile">
      <div
        class="main"
        :class="{ 'main--logged-in': showNavBar }"
        v-if="hasTeams"
      >
        <router-view />
      </div>
      <NoTeam v-else />
    </div>
    <div v-else>
      <div
        class="main"
        :class="{ 'main--logged-in': showNavBar }"
        v-if="hasTeams"
      >
        <router-view />
      </div>
    </div>

    <div class="notification__wrapper" v-if="hasNotification">
      <Notification
        v-for="(notification, index) in notifications"
        :key="index"
        :type="notification.type"
        :notification-id="notification.id"
      >
        <div class="notification__title">
          {{ $t(`components.notifications.${notification.title}`) }}
        </div>
      </Notification>

      <div class="progress">
        <Notification
          :show-close-button="false"
          class="progress-note"
          v-if="progressLandscape > 0"
        >
          <div class="progress-note-title">
            Landscape Upload:
          </div>
          <div class="progress-note-right">
            <div class="progress-note-bar-wrapper">
              <div
                class="progress-note-bar"
                :style="{ width: `${progressLandscape}%` }"
              ></div>
            </div>
            <div class="progress-note-percentage">
              {{ Math.round(progressLandscape) }}%
            </div>
          </div>
        </Notification>

        <Notification
          :show-close-button="false"
          class="progress-note"
          v-if="progressPortrait > 0"
        >
          <div class="progress-note-title">
            Portrait Upload:
          </div>
          <div class="progress-note-right">
            <div class="progress-note-bar-wrapper">
              <div
                class="progress-note-bar"
                :style="{ width: `${progressPortrait}%` }"
              ></div>
            </div>
            <div class="progress-note-percentage">
              {{ Math.round(progressPortrait) }}%
            </div>
          </div>
        </Notification>

        <Notification
          :show-close-button="false"
          class="progress-note"
          v-if="progressPicture > 0"
        >
          <div class="progress-note-title">
            Upload:
          </div>
          <div class="progress-note-right">
            <div class="progress-note-bar-wrapper">
              <div
                class="progress-note-bar"
                :style="{ width: `${progressPicture}%` }"
              ></div>
            </div>
            <div class="progress-note-percentage">
              {{ Math.round(progressPicture) }}%
            </div>
          </div>
        </Notification>
      </div>
    </div>

    <ChangeLog v-if="showChangeLog" />
    <LoadingOverlay v-if="isUploading" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Notification from "@/components/Notification";
import NavBar from "@/components/NavBar";
import NoTeam from "@/components/NoTeam";
import NavBarMobile from "@/components/NavBarMobile";
import ChangeLog from "@/components/ChangeLog";
import LoadingOverlay from "@/components/LoadingOverlay";

export default {
  components: {
    NavBar,
    NavBarMobile,
    Notification,
    NoTeam,
    ChangeLog,
    LoadingOverlay
  },
  computed: {
    ...mapGetters("progress", {
      progressLandscape: "getProgressLandscape",
      progressPortrait: "getProgressPortrait",
      progressPicture: "getProgressPicture"
    }),
    ...mapGetters("notifications", {
      notifications: "getNotifications"
    }),
    ...mapGetters("user", {
      language: "getLanguage",
      user: "getCurrentUser",
      profile: "getUserProfile"
    }),
    ...mapGetters("slides", {
      isUploading: "getIsUploading"
    }),
    showNavBar() {
      return (
        this.user &&
        this.$route.name !== "Editor" &&
        this.$route.name !== "EditorNew" &&
        this.$route.name !== "Designer" &&
        this.$route.name !== "PreviewTemplate" &&
        this.$route.name !== "PreviewSlide" &&
        this.$route.name !== "Login" &&
        this.$route.name !== "Register" &&
        this.$route.name !== "Invite" &&
        this.$route.name !== "PasswordReset" &&
        this.$route.name !== "Imprint" &&
        this.$route.name !== "TermsOfUse" &&
        this.$route.name !== "DataPrivacy" &&
        this.$route.name !== "BrandImport" &&
        this.$route.name !== "SlidePreview"
      );
    },
    showChangeLog() {
      return (
        this.user &&
        this.$route.name !== "Login" &&
        this.$route.name !== "Register" &&
        this.$route.name !== "Invite" &&
        this.$route.name !== "PasswordReset"
      );
    },
    hasTeams() {
      if (this.user) {
        return (
          this.profile && this.profile.teams && this.profile.teams.length > 0
        );
      }

      return true;
    },
    hasNotification() {
      if (
        this.notifications.length > 0 ||
        this.progressPicture > 0 ||
        this.progressLandscape > 0 ||
        this.progressPortrait > 0
      ) {
        return true;
      }

      return false;
    },
    hasUser() {
      return !!this.user;
    }
  },
  created() {
    this.getAuthState();
    this.fetchLogData();
    this.$i18n.i18next.changeLanguage(localStorage.getItem("language"));
  },
  watch: {
    language() {
      this.$i18n.i18next.changeLanguage(this.language);
    },
    progressLandscape() {
      if (this.progressLandscape === 100) {
        setTimeout(() => {
          this.updateProgress("landscape");
        }, 2000);
      }
    },
    progressPortrait() {
      if (this.progressPortrait === 100) {
        setTimeout(() => {
          this.updateProgress("portrait");
        }, 2000);
      }
    },
    progressPicture() {
      if (this.progressPicture === 100) {
        setTimeout(() => {
          this.updateProgress("picture");
        }, 2000);
      }
    },
    hasUser() {
      if (!this.hasUser) {
        sessionStorage.removeItem("currentTeam");
        this.logout();
      }
    }
  },
  methods: {
    ...mapActions("progress", ["updateProgress"]),
    ...mapActions("user", ["logout", "getAuthState", "fetchLogData"])
  }
};
</script>

<style lang="scss">
.only-desktop {
  display: none;
}

.app {
  @apply bg-ls-gray-100;
  font-family: "SpeziaWeb", sans-serif;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;

  .navbar--desktop {
    display: none;
  }

  .main--logged-in {
    padding: 59px 0 0 0;
  }

  .styleTitle {
    font-family: "title_font";
  }

  .styleHeadline {
    font-family: "headline_font";
  }

  .styleSubHeadline {
    font-family: "subHeadline_font";
  }

  .styleNormalText {
    font-family: "normalText_font";
  }

  .styleSmallText {
    font-family: "smallText_font";
  }

  .styleCaption {
    font-family: "caption_font";
  }

  .contain {
    object-fit: contain !important;
  }

  .cover {
    object-fit: cover !important;
  }
}

@media screen and (min-width: 900px) {
  .only-desktop {
    display: block;
  }
  .app {
    .navbar--desktop {
      display: block;
    }

    .navbar--mobile {
      display: none;
    }

    .main--logged-in {
      padding: 0 0 0 208px;
    }

    .progress {
      @apply flex flex-col;

      &-note {
        @apply mb-2;
        transition: 0.3s all ease-in-out;

        .notification__text {
          @apply flex justify-between w-full;
        }

        &-title {
          @apply mr-4;
        }

        &-right {
          @apply flex;
        }

        &-bar {
          height: 4px;
          @apply bg-ls-green;
          trans: 0.2s all ease-in-out;

          &-wrapper {
            display: flex;
            align-items: center;
            width: 150px;
            margin-right: 16px;
          }
        }

        &-percentage {
        }
      }
    }

    .notification__wrapper {
      @apply flex fixed flex-col;
      bottom: 2rem;
      right: 3rem;
      width: 410px;
      z-index: 50;
    }

    .notification:not(:last-child) {
      @apply mb-3;
    }
  }
}
</style>
